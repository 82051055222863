import * as React from "react";
import HcpLayout from "../components/hcp/layout";
import { Row, Col } from "react-grid-system";
import { Seo } from "../components/seo";
import useDeviceSize from "../hooks/use-device-size";
import { TfiAngleRight } from "react-icons/tfi";
import "./talking-treatment.scss";
import "../sass/_orphan.scss";

import bannerImg from "../images/ess/chris-social-header-desktop.webp";
import bannerImgMobile from "../images/ess/chris-social-header-mobile.webp";
import pdfThumb from "../images/orphan/thumb-pdf-talking-treatment.webp";

import heroName from "../images/ess/hero-ess-name.webp";

const TalkingTreatmentPage = () => {
    const deviceSizes = useDeviceSize();
    return (
        <HcpLayout
            pageClass="talking-treatment-page orphan-page"
            jobCode="US-WAK-2300499/Dec 2023"
            isGhostPage="true"
        >
            <div className="hero-wrapper row-full">
                <div className="hero-content-wrapper">
                    <div className="hero-banner inner bg-cover">
                        {deviceSizes?.smUp ? (
                            <img
                                src={bannerImg}
                                className="banner-mobile"
                                alt="Talking About Your Treatment"
                            />
                        ) : (
                            <img
                                src={bannerImgMobile}
                                className="banner-desktop"
                                alt="Talking About Your Treatment"
                            />
                        )}
                        <div className="centered-content">
                            <div className="hero-name">
                                <img
                                    src={heroName}
                                    alt="Chris - Living with narcolepsy Taking WAKIX since 2021"
                                />
                            </div>
                        </div>
                        {/* <div className="banner-text show-desktop">
                <div className="centered-content">
                  <h1 className="h1-lrg">Talking About <br />Your Treatment</h1>
                </div>
              </div> */}
                    </div>
                </div>
            </div>
            {/* <section>
          <div className="centered-content show-mobile">
            <h1 className="h1-lrg">Talking About Your Treatment</h1>
          </div>
        </section> */}
            <section className="centered-content">
                <div className="page-heading ph-btm">
                    <h1 className="font-40">Talking About Your Treatment</h1>
                </div>
            </section>
            <section className="gray-bg cta-block">
                <div className="centered-content">
                    <Row className="talking-row">
                        <Col xs={12} md={9} className="talking-col">
                            <div className="content-copy">
                                <p
                                    className="btm-spacer"
                                    style={{ marginTop: "0" }}
                                >
                                    After starting your treatment for excessive
                                    daytime sleepiness (EDS) or cataplexy in
                                    narcolepsy with WAKIX, it's important to
                                    talk to your healthcare provider about how
                                    you are feeling. Be sure to share any
                                    questions or concerns that you have about
                                    treatment and tell them about any
                                    improvements—whether big or small—as well as
                                    any side effects you notice.
                                </p>
                                <p className="strong top-spacer btm-spacer">
                                    Prepare for your next appointment by using
                                    this personalized discussion guide. You can
                                    choose what you want to discuss, create a
                                    list of specific questions you want to ask,
                                    and write down what you want to remember to
                                    do after your appointment.
                                </p>
                                <div className="btn-wrap">
                                    <a
                                        href="/pdf/WAKIX-guide-to-talking-about-treatment-with-your-doctor.pdf"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        className="btn btn-primary btn-primary--md normal"
                                    >
                                        Get your guide
                                        <TfiAngleRight />
                                    </a>
                                </div>
                            </div>
                        </Col>
                        <Col
                            xs={12}
                            md={3}
                            className="talking-col talking-col-2"
                        >
                            <img
                                src={pdfThumb}
                                alt="Talking about your treatment thumbnail"
                                className="box-shadow"
                            />
                        </Col>
                    </Row>
                </div>
            </section>
        </HcpLayout>
    );
};

export default TalkingTreatmentPage;

export const Head = () => (
    <Seo
        title="Talking With Your Healthcare Provider | WAKIX® (pitolisant) tablets"
        description="Use this discussion guide to help prepare for your appointment with your healthcare provider."
        keywords=""
    />
);
